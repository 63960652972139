.mr-0{
    margin-right: 0 !important;
}

select {
    word-wrap: normal;
    height: 2.5rem;
    background-color: #fff;
    border-radius: 0.375rem;
    border-color: #e6e6e6;
    padding: 8px;
    width: 100%;
    color: #585858;
}

#main-wrapper {
    z-index: 1;
}

.form-check-label {
    cursor: pointer;
    white-space: nowrap;
    margin-top: 0 !important;
}

.form-check-inline .form-check-input {
    margin-right: 0 !important;
}

/* Chrome, Safari, Edge, Opera */
input.phoneNum::-webkit-outer-spin-button,
input.phoneNum::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input.nocount::-webkit-outer-spin-button,
input.nocount::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox  */
input[type='number'].phoneNum {
    -moz-appearance: textfield;
}

input[type='number'].nocount {
    -moz-appearance: textfield;
}

.secessionDate {
    width: 300px;
}

textarea.form-control {
    resize: none;
}

.table tbody tr td {
    white-space: nowrap;
}

.reasonbtn {
    border: 0;
    margin-left: 10px;
    background-color: transparent;
    color: #FF1616;
    border-bottom: 1px solid #FF1616;
    font-size: 13px;
    font-weight: 600;
}

.badge {
    font-size: 14px !important;
}

.reasonmodal .modal-body .content {
    font-size: 16px; 
}
.reasonmodal .modal-body .content .linktxt{
    word-break: break-all;
}

.card-header .btn {
    font-size: .9375rem !important;
}

.paging .pagination {
    justify-content: center;
}

caption {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

.weekday-buttons {
    display: flex;
    gap: 6px;
    flex-wrap: wrap;
}

.weekday-buttons .p-button {
    background-color: #F1F1F1 !important;
    color: #111;
    border: 0;
}


.custom-react-select{
    position: relative !important;
    z-index: 4;
}

/* .css-b62m3t-container{
    z-index: 9;
} */


.selectedBox {
    color: #E8374A;
    font-weight: 600;
}
.selectedBox > p{
    font-size: 15px;
}
.selectedBox .xicon{
    border: 0;
    color: #000;
    padding: 0;
    margin: 0;
    background-color: transparent;
    font-size: 13px;
    font-weight: bold;
    padding-left: 5px;
}

.form-switch .form-check-input:checked {
    background-size: auto;
}

.form-switch .form-check-input {
    width: 3em !important;
}

.custom-tooltip .tooltip-inner {
    background-color: #fff;
    color: #000;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.tooltip-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.tooltip-list li {
    margin-bottom: 5px;
}

.tooltip-list li:last-child {
    margin-bottom: 0;
}

.link {
    color: #33b8ff;
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
}

.fileList {
    padding: 0.3125rem .95rem;
    border-radius: 0.375rem;
    border: 1px solid #e6e6e6;
    margin-bottom: 1rem;
}

.fileList ul li .btn {
    margin: 0;
    padding: 0;
    padding-top: 5px;
    margin-left: 5px;
}

.option {
    width: 20px;
    height: 16px;
    border-radius: 50%;
    display: inline-block;
    border: 1px solid #ddd
}


/* 숨겨진 라디오 버튼 스타일링 */
.colorOption input[type="radio"] {
  display: none; /* 라디오 버튼 숨김 */
}

/* 라디오 버튼을 대체하는 스타일 */
.colorOption::before {
  content: '';
  display: inline-block; 
  width: 25px; 
  height: 25px; 


}

/* 라디오 버튼이 선택된 경우 */
.colorOption input[type="radio"]:checked + .colorBox {
    border-color: green; 
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); 
}

/* 색상 옵션 컨테이너 */
.colorOption {
  display: inline-block;
  cursor: pointer;
  margin-right: 10px;
  position: relative;
}

.fc .fc-daygrid-event{
    padding: 0;
    color: #fff;
}
.eventBox{
    padding: 0.3125rem;
    width: 100%;
}


/* 색상 상자 스타일 */
.colorBox {
  display: inline-block;
  width: 25px;
  height: 25px;
  position: absolute;
  left: 0;
  top: 0;   
}

/* 각 색상에 따른 마크 색상 지정 */
.coral::before {
  background-color: coral;
}

.red::before {
  background-color: red;
}

.blue::before {
  background-color: blue;
}

.orange::before {
  background-color: orange;
}

.purple::before {
  background-color: purple;
}

.green::before {
  background-color: green;
}


/* 슈퍼어드민 */
.superadminList{
    max-width: 500px;
}

.adminname{
    font-size: 0.875rem;
}

/* 배너관리 */
.preview{
    width: 172px;
    height: 80px;
}
.preview img{
    width: 100%;
    height: 100%;
}


/* 대시보드 */
.highlight {
  background: #f3a95f;
}

#external-events .external-event{
    cursor:inherit
}

#external-events .external-event:hover{
        background-color: rgba(106, 115, 250, 0.1);
    border-color: var(--primary);
    color: #6a73fa;
}


.react-calendar{
    box-shadow: none !important;
}


button.react-calendar__navigation__arrow.react-calendar__navigation__prev-button,button.react-calendar__navigation__arrow.react-calendar__navigation__next-button{
    display: block;
}

.media.bg-red,.progress.bg-red{
    background-color: #ff6175 !important;
}

.widget-stat .media {
    padding: 1rem !important;
    border-radius: 8px;
}

 button.react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day.today >abbr {
    width: 100%;
    background-color: #d0d0d0  !important;
}
button.react-calendar__tile.react-calendar__tile--now.react-calendar__month-view__days__day::before, button.react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day::before{
    display: none;
}

button.react-calendar__tile.react-calendar__month-view__days__day.event-red > abbr{
    background-color: red; 
        color: #fff;
}

button.react-calendar__tile.react-calendar__month-view__days__day.event-blue > abbr{
    background-color: blue; 
    color: #fff;
}

button.react-calendar__tile.react-calendar__month-view__days__day.event-green > abbr{
    background-color: green; 
    color: #fff;
}

button.react-calendar__tile.react-calendar__month-view__days__day.event-purple > abbr{
    background-color: purple; 
        color: #fff;
}

button.react-calendar__tile.react-calendar__month-view__days__day.event-coral > abbr{
    background-color: coral; 
     color: #fff;
}

button.react-calendar__tile.react-calendar__month-view__days__day.event-orange > abbr{
    background-color: orange; 
     color: #fff;
}




.nav-header{
    padding: 0 5px;
}
.nav-header .brand-logo{
 background-repeat: no-repeat;
    
    background-position: center;
}
[data-sidebar-style="full"] .nav-header .brand-logo{
    background-image: url(../../assets/img/logo.png);
}
[data-sidebar-style="mini"] .nav-header .brand-logo{
    background-image: url(../../assets/img/logo.png);
    background-size: contain;
}
[data-sidebar-style="overlay"] .nav-header .brand-logo{
    background-image: url(../../assets/img/moblogo.png);
}
[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .brand-logo{
     background-image: url(../../assets/img/moblogo.png);
}
.css-b62m3t-container.z4{
    z-index: 4;
}

.navbar-expand .navbar-nav:hover{
    cursor: pointer;
}

.rs-anim-fade.rs-anim-in{
    z-index: 1056;
}
/* .fc-timegrid-event-harness{
    position: static;
} */
/* .fc-media-screen .fc-timegrid-event-harness{
    inset: 60px -1% 0 0% !important;
} */

.imagePreview {
    width:100px;
    margin-top:10px;
}

.my-cloud .vodBox{
    height: 200px;
    background-color: gray;
    color: #fff;
    text-align: center;
    line-height: 190px;
}

span.danger {
    color:red;
}

/* .css-1nmdiq5-menu{z-index: 100!important;} */
.sectionNumBox{
     border-bottom: 1px solid #673BB7
}
.sectionNum {
    background-color: #673BB7;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px 5px 0 0;
    width: auto;
    display: inline-block;
}

.moreicon button{
    width: auto;
    height: auto;
}
.moreicon .sharp{
    margin: 0 !important;
    padding: 0 !important;
    min-width: none;    
    min-height: auto;
}

.row > .sub_questBox{
    margin-top: 2rem;
}
.sub_questBox .card{
    border-radius: 5px;
    margin-bottom: 10px;
    position: static;
    height: auto;
}
.sub_questBox.on .card{
    border: 1px solid #6a73fa;
}

@media(max-width: 575px) {
    .d-block.labelhidden {
        display: none !important;
    }
}